import React from "react";
import { connect } from "react-redux";

import UserProfile from "../../../app/partials/layout/UserProfile";
import InquiryManagementNoti from "../../../app/partials/layout/InquiryManagementNoti";
import checkPermission from "../../../app/libs/permission";

class Topbar extends React.Component {
	render() {
		const { page, pageSubmenu, user } = this.props;

		return (
			<div className={`kt-header__topbar ${page?.content || pageSubmenu?.content ? 'w-100 d-flex justify-content-between align-items-center' : ''}`}>
				{page?.content || pageSubmenu?.content ?
					<div className='default-black-color'>
						<span className='f-semibold_30'>{user?.is_marker === 1 && page?.content === 'Dashboard' ? 'Review Test' : (page?.content || pageSubmenu?.content)}</span>
					</div>
					: <></>}
				<div className="d-flex">
					{checkPermission('inquiry.view') ? <InquiryManagementNoti /> : <></>}
					<UserProfile isNewUI={true} showAvatar={true} showHi={false} showBadge={false} />
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ auth: { user } }) => ({
	user: user ?? {},
});

export default connect(mapStateToProps)(Topbar);
