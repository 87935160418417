/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";
import objectPath from "object-path";
import Header from "./header/Header";
import SubHeader from "./sub-header/SubHeader";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import { useHistory } from "react-router-dom";
// import Footer from "./footer/Footer";
import ScrollTop from "../../app/partials/layout/ScrollTop";
// import StickyToolbar from "../../app/partials/layout/StickyToolbar";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import MenuConfig from "./MenuConfig";
import LayoutInitializer from "./LayoutInitializer";
import QuickPanel from "../../app/partials/layout/QuickPanel";
import KtContent from "./KtContent";
import "./assets/Base.scss";
import makeRequest from "../../app/libs/request";
import * as auth from "../../app/store/ducks/auth.duck";
import { showErrorMessage } from "../../app/actions/notification";
import moment from "moment";

import { matchPath } from "react-router-dom";

const htmlClassService = new HTMLClassService();

function Layout(props) {
	let { children, asideDisplay, subheaderDisplay, selfLayout, layoutConfig } = props;
	htmlClassService.setConfig(layoutConfig);
	// scroll to top after location changes
	// window.scrollTo(0, 0);
	const [listNotify, setListNotify] = useState([]);
	const [settingData, setSetting] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const [user, setUser] = useState(false);
	const history = useHistory();
	const getCountStatusNew = (corporate_id) => {
		makeRequest("get", `inquiry/countStatusNew`, { corporate_id })
			.then(({ data }) => {
				if (data.signal) {
					let list = document.getElementsByClassName("inquiryStatusNew");
					let listNoti = document.getElementsByClassName("inquiry-noti-number");
					if (listNoti[0]) {
						listNoti[0].innerHTML = data.data;
						if (data.data === 0) {
							listNoti[0].remove();
						}
					}
					if (list[0]) {
						list[0].innerHTML = data.data;
						if (data.data === 0) {
							list[0].remove();
						}
					}
					if (data.data > 0 && !listNoti[0]) {
						const divInquiryNoti = document.querySelector('.link-inquiry .notification-icon-2');
						if (divInquiryNoti && data.data > 0) {
							divInquiryNoti.innerHTML += `<div class="border-radius-99 noti-number inquiry-noti-number" >${data.data}</div>`;
						}
					}
					if (data.data > 0 && !list[0]) {
						const divInquiry = document.querySelector("[href='/inquiry/list']:not(.link-inquiry)");
						if (divInquiry && data.data > 0)
							divInquiry.innerHTML += `<span class="kt-menu__link-badge"><span class="kt-badge kt-badge--md kt-badge--rounded inquiryStatusNew">${data.data}</span></span>`;
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getCorById = (id) => {
		makeRequest("get", `corporate/cloneData`, { id }).then(async ({ data }) => {
			if (data.signal) {
				const item = data.data;
				const setting = {
					type: "cor",
					item: data.data,
				};
				await localStorage.setItem("setting", JSON.stringify(setting));
				setSetting(setting);
				if (item.status === 1) {
					setShowMenu(true);
					getCountStatusNew(id);
				} else if (item.status === 0) {
					showErrorMessage("This company is inactive. Can not login.");
					props.logout();
				} else {
					setShowMenu(false);
					if (item.expire_month) {
						if (parseInt(item.exprie_year) < parseInt(moment().format("YY"))) {
							showErrorMessage("Payment information has expired.");
						} else if (parseInt(item.exprie_month) < parseInt(moment().format("MM"))) {
							showErrorMessage("Payment information has expired.");
						}
					}
					history.push("/subscription-plan");
				}
			}
		});
	};
	const getDataUser = () => {
		makeRequest("get", "user/userInfo").then(({ data }) => {
			if (data.signal) {
				let dataUser = data.data;
				if (
					(dataUser.is_manager !== 1 && dataUser.is_marker !== 1) ||
					dataUser.is_active !== 1 ||
					dataUser.status !== 1
				) {
					props.logout();
				}
				if (dataUser.corporate_id) {
					setUser(true);
					getCorById(dataUser.corporate_id);
					let arrayPermissions = data.data.arrayPermissions;
					localStorage.setItem("x-permission", JSON.stringify(arrayPermissions));
				}
			} else {
				props.logout();
			}
		});
	};
	useEffect(() => {
		if (!user || !settingData) {
			getDataUser();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	const clickOkModal = () => {
		let newList = [];

		let id = listNotify[0].id;

		if (listNotify.length > 1) {
			newList = listNotify.slice(1, listNotify.length);
		} else {
			newList = listNotify.slice(0, 0);
		}

		setListNotify(newList);

		makeRequest("get", `notification/createdistrinotify?notify_id=${id}`)
			.then(() => { })
			.catch((err) => {
				console.log(err);
			});
	};

	const clickCancelModal = () => {
		let newList = [];
		newList = listNotify.slice(0, 0);
		setListNotify(newList);
	};

	const menuNav = MenuConfig?.aside ? MenuConfig?.aside?.items : []

	const findSubMenu = (submenu = []) => {
		return submenu.find((item) => {
			if (item?.pathname) {
				return item.pathname?.find((item) => matchPath(props?.location?.pathname, item.path))
			}
			if (item?.submenu) {
				return item.submenu.find((item) => {
					if (item.pathname) {
						return item.pathname?.find((item) => matchPath(props?.location?.pathname, item.path))
					}
					if (item.submenu) {
						return item.submenu.find((item) => item.pathname?.find((item) => matchPath(props?.location?.pathname, item.path)))
					}
					return false
				})
			}
			return false
		})
	}

	const page = findSubMenu(menuNav)

	const navSubmenu = page?.submenu
		? findSubMenu(page.submenu)
		: {}
	const pageSubmenu = navSubmenu?.submenu ? findSubMenu(navSubmenu.submenu) : navSubmenu
	const pageHeader = page?.pathname
		? page.pathname.find((item) => matchPath(props?.location?.pathname, item.path))
		: {}
	const pageSubmenuHeader = pageSubmenu?.pathname
		? pageSubmenu.pathname.find((item) => matchPath(props?.location?.pathname, item.path))
		: {}

	return selfLayout !== "blank" ? (
		<LayoutInitializer menuConfig={MenuConfig} layoutConfig={LayoutConfig} htmlClassService={htmlClassService}>
			{/* <!-- begin:: Header Mobile --> */}
			<HeaderMobile />
			{/* <!-- end:: Header Mobile --> */}

			<div className="kt-grid kt-grid--hor kt-grid--root">
				{/* <!-- begin::Body --> */}
				<div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
					{/* <!-- begin:: Aside Left --> */}
					{showMenu
						? asideDisplay && (
							<>
								<AsideLeft setting={settingData} />
							</>
						)
						: ""}
					{/* <!-- end:: Aside Left --> */}
					<div
						className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
						id="kt_wrapper"
						style={{ paddingLeft: !showMenu ? 0 : "", margin: !showMenu ? "0 auto" : "" }}
					>
						{/* <!-- begin:: Header READY --> */}

						<Header showMenu={showMenu} page={pageHeader} pageSubmenu={pageSubmenuHeader} />
						{/* <!-- end:: Header --> */}

						{/* <!-- begin:: Content --> */}
						<div id="kt_content">
							{/* <!-- begin:: Content Head --> */}
							{subheaderDisplay && <SubHeader />}
							{/* <!-- end:: Content Head --> */}

							{/* <!-- begin:: Content Body --> */}
							{/* TODO: add class to animate  kt-grid--animateContent-finished */}
							<KtContent>{children}</KtContent>
							{/*<!-- end:: Content Body -->*/}
						</div>
						{/* <!-- end:: Content --> */}
						{/* <Footer /> */}
					</div>
				</div>
				{/* <!-- end:: Body --> */}
			</div>
			<QuickPanel />
			<ScrollTop />
			<Modal
				title="Thông báo"
				visible={listNotify && listNotify.length > 0 ? true : false}
				cancelText="Đóng tất cả"
				okText="Đánh dấu đã đọc"
				onCancel={clickCancelModal}
				onOk={clickOkModal}
				width={500}
			>
				<div className="modal-header d-flex justify-content-center">
					<h5 className="heading text-h5">{listNotify && listNotify.length > 0 ? listNotify[0].title : null}</h5>
				</div>
				<div>
					<div>
						<div className="modal-dialog modal-notify modal-info" role="document">
							<div className="modal-content text-center">
								<div className="modal-body">
									<i className="fas fa-bell fa-4x animated rotateIn mb-4" />
									<p>{listNotify && listNotify.length > 0 ? listNotify[0].content : null}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			{/* <StickyToolbar /> */}
		</LayoutInitializer>
	) : (
		// BLANK LAYOUT
		<div className="kt-grid kt-grid--ver kt-grid--root">
			<KtContent>{children}</KtContent>
		</div>
	);
}

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
	layoutConfig,
	selfLayout: objectPath.get(layoutConfig, "self.layout"),
	asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
	subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
	desktopHeaderDisplay: objectPath.get(layoutConfig, "header.self.fixed.desktop"),
	contentContainerClasses: "",
});

export default connect(mapStateToProps, auth.actions)(Layout);
